import { Box, ChakraProvider, Checkbox, CheckboxGroup, Flex, Heading, HStack, Radio, RadioGroup, Text, useRadioGroup, VStack } from "@chakra-ui/react";
import * as React from "react";
import { createRoot } from "react-dom/client";
import "./styles.css";
import * as R from "remeda"

const PUBLISHERS = [
  {
    id: 1,
    name: "Bitquery",
    traffic: 36,
    tags: [
      "builders",
      "memecoins",
      "stablecoins",
      "volume-medium",
      "network-evm",
      "network-nonevm",
    ]
  },
  {
    id: 2,
    name: "Cometa.farm",
    traffic: 36,
    tags: [
      "defi",
      "memecoins",
      "volume-high",
      "network-nonevm",
      "network-algorand",
    ]
  },
  {
    id: 3,
    name: "Vestige.fi",
    traffic: 36,
    tags: [
      "trading",
      "defi",
      "memecoins",
      "volume-medium",
      "network-nonevm",
      "network-algorand",
    ]
  },
  {
    id: 4,
    name: "StarkScan",
    traffic: 36,
    tags: [
      "builders",
      "zk",
      "memecoins",
      "stablecoins",
      "volume-medium",
      "network-evm",
    ]
  },
  {
    id: 5,
    name: "Blockscout",
    traffic: 36,
    tags: [
      "builders",
      "zk",
      "stablecoins",
      "volume-medium",
      "network-evm",
      "network-nonevm",
    ]
  },
  {
    id: 6,
    name: "howrare.is",
    traffic: 36,
    tags: [
      "nft",
      "trading",
      "gamefi",
      "volume-medium",
      "network-nonevm",
      "network-solana",
    ]
  },
]

type TGElement = readonly [tag: string, percent: number, demand: number]
type TargetingGroup = readonly TGElement[]

const MODIFIERS = {
  device: [
    ["desktop", 0.8, 0.8],
    ["mobile", 0.1, 0.15],
    ["unknown", 0.1, 0.05],
  ],
  os: [
    ["android", 0.06, 0.08],
    ["ios", 0.045, 0.1],
    ["windows", 0.55, 0.5],
    ["macos", 0.25, 0.35],
    ["linux", 0, 0],
    ["unknown", 0, 0],
  ],
  connected: [
    ["yes", 0.55, 0.75],
    ["no", 0.45, 0.35],
  ],
  chain: [
    ["All EVM chains", 0.895049504950495, 0.95],
    ["Non-EVM chains", 0.620792079207921, 0.35],
    ["Ethereum", 0, 0],
    ["Solana", 0.0212871287128713, 0.1],
    ["Avalanche", 0, 0],
    ["Starknet", 0.378217821782178, 0.35],
    ["Algorand", 0.0836633663366337, 0.07],
  ],
  interests: [
    ["DeFi", 0.0881188118811881, 0.3],
    ["NFT", 0.122277227722772, 0.3],
    ["Web3 Games", 0.0222772277227723, 0.12],
    ["Trading", 0.0826732673267327, 0.16],
    ["Memecoins", 0.862376237623763, 0.5],
    ["Builder", 0.88960396039604, 0.99],
    ["ZK", 0.489108910891089, 0.7],
    ["Stablecoins", 0.894059405940594, 0.95],
  ],
  volume: [
    ["low", 0.0311881188118812, 0.01],
    ["medium", 0.967821782178218, 0.99],
    ["high", 0.01, 0.2],
  ],
} as const

type TargetingGroupNames = keyof typeof MODIFIERS
type SelectedTargetingTagsList = Record<TargetingGroupNames, string[]>
const TARGETING_GROUP_NAMES = Object.keys(MODIFIERS).sort()

function suitablePublishers(tags: string[])
{
  return PUBLISHERS.filter(pub => tags.every(tag => pub.tags.includes(tag)))
}
function getTotalModifier(group: TargetingGroup, selectedTags: string[])
{
  if (!selectedTags.length)
    return 1
  
  let selected = group.filter(([name]) => selectedTags.includes(name))
  let { tp: totalPercent, td: totalDemand } = selected.reduce((acc, [_,p,d]) => ({ tp: acc.tp + p, td: acc.td + d }), {tp: 0, td: 0})
  if (totalPercent == 0)
    return 0

  return totalDemand / totalPercent
}
function getModifiers(tagsList: SelectedTargetingTagsList)
{
  return R.mapValues(tagsList, (value, key) => getTotalModifier(MODIFIERS[key], value))
}
function totalModifier(tagsList: SelectedTargetingTagsList)
{
  let mods = getModifiers(tagsList)
  let modsArray = TARGETING_GROUP_NAMES.map(x => mods[x])
  let total = modsArray.reduce((acc, cur) => acc * cur, 1)
  return total
}

function getTotalAudiencePercent(group: TargetingGroup, selectedTags: string[])
{
  if (!selectedTags.length)
    return 1

  let selected = group.filter(([name]) => selectedTags.includes(name))
  let totalPercent = selected.reduce((acc, [_,p]) => acc + p, 0)

  return totalPercent
}
function audienceSize(tagsList: SelectedTargetingTagsList)
{
  let percentages = R.mapValues(tagsList, (value, key) => getTotalAudiencePercent(MODIFIERS[key], value))
  let percArray = TARGETING_GROUP_NAMES.map(x => percentages[x])
  let total = percArray.reduce((acc, cur) => acc * cur, 1)
  if (total > 1)
    return 1

  return total
}

function Selector(props: { name: string, group: TargetingGroup, value: string[], setValue: (value: string[]) => void })
{
  return (
    <VStack align="flex-start">
      <Heading size="md">{props.name}:</Heading>
      <CheckboxGroup value={props.value} onChange={props.setValue}>
        <Box>
          {props.group.map(([tagName]) => (
            <Checkbox key={tagName} value={tagName}>{tagName}</Checkbox>
          ))}
        </Box>
      </CheckboxGroup>
    </VStack>
  )
}

const percent = (n: number, decimals = 4) => (n * 100).toFixed(decimals) + '%'
const dollar = (n: number | string) => `$${(typeof n == "number") ? n.toFixed(2) : n}`.replace('$-', '-$')
// const shortenWithDecimals = (n: number, decimals: )
const shorten = (n: number) =>
{
  const k = (n: number, decimals?: number) => (n / 1000).toFixed(decimals) + "K"
  const M = (n: number, decimals?: number) => (n / 1_000_000).toFixed(decimals) + "M"
  const B = (n: number, decimals?: number) => (n / 1_000_000_000).toFixed(decimals) + "B"

  if (n < 1000)
    return n.toFixed(0)

  if (n < 10_000)
    return k(n, 1)
  if (n < 1_000_000)
    return k(n, 0)

  if (n < 10_000_000)
    return M(n, 1)
  if (n < 1_000_000_000)
    return M(n, 0)

  if (n < 10_000_000_000)
    return B(n, 2)
  if (n < 100_000_000_000)
    return B(n, 1)

  return B(n, 0)
}

const monthlyBudget = (cpm: number, audience: number) => cpm * audience / 1000

const CPM_USD_DEFAULT = 6.67
const AUDIENCE_SIZE_DEFAULT = 2_500_000

function App()
{
  let [mods, setMods] = React.useState(R.mapValues(MODIFIERS, () => []))
  let mod = getModifiers(mods)
  console.log(mod)

  let audiencePercent = audienceSize(mods)
  let audienceAmount = audiencePercent * AUDIENCE_SIZE_DEFAULT
  let audience = { min: audienceAmount * 0.8, max: audienceAmount * 1.2 }
  let totalCpm = totalModifier(mods) * CPM_USD_DEFAULT
  let cpm = { min: totalCpm * 0.8, max: totalCpm * 1.5 }
  let budget = {
    min: monthlyBudget(cpm.min, audience.min),
    max: monthlyBudget(cpm.max, audience.max),
  }

  return (
    <Box>
      {TARGETING_GROUP_NAMES.map(name => (
        <Selector
          key={name}
          name={name}
          group={MODIFIERS[name]}
          value={mods[name]}
          setValue={val => setMods(s => ({...s, [name]: val}))}
        />
      ))}
      <br/><hr/><br/>

      {/* <Heading size="lg">Audience size: {percent(audiencePercent)}</Heading> */}
      <Heading size="lg">Audience size: {shorten(audience.min)} - {shorten(audience.max)}</Heading>
      {/* <Heading size="sm">CPM: {dollar(cpm.min)} - {dollar(cpm.max)}</Heading> */}
      <Heading size="lg">Approx CPM: ~{dollar(totalCpm)}</Heading>
      {/* <Heading size="sm">Monthly budget: {dollar(shorten(budget.min))} - {dollar(shorten(budget.max))}</Heading> */}
      <br/><hr/><br/>
      <Heading size="md">Modifiers:</Heading>
      <HStack>
        {TARGETING_GROUP_NAMES.map(name => (
          mods[name].length && <Text key={name}>{name}: <b>x{mod[name].toFixed(2)}</b></Text>
        ))}
      </HStack>

    </Box>
  )
}

// function App() {
//   let [device, setDevice] = React.useState("")
//   let [os, setOs] = React.useState([])
//   let [volume, setVolume] = React.useState("")

//   let tagsList = [
//     ...(volume ? [`volume-${volume}`] : [])
//   ]

//   let pubs = suitablePublishers(tagsList)

//   console.log(Date.now(), volume, tagsList, pubs)

//   return <Box>
//     <Heading>Welcome to Chakra + TS</Heading>
//     <VStack align="flex-start">
//       <Heading size="md">Device:</Heading>
//       <RadioGroup value={device} onChange={setDevice}>
//         <HStack>
//           <Radio value="">-disable-</Radio>
//           <Radio value="mobile">Mobile</Radio>
//           <Radio value="desktop">Desktop</Radio>
//         </HStack>
//       </RadioGroup>
//       <Heading size="md">OS:</Heading>
//       <CheckboxGroup value={os} onChange={setOs}>
//         <HStack>
//           <Checkbox value="ios">iOS</Checkbox>
//           <Checkbox value="android">Android</Checkbox>
//           <Checkbox value="macos">MacOS</Checkbox>
//           <Checkbox value="windows">Windows</Checkbox>
//           <Checkbox value="linux">Linux</Checkbox>
//         </HStack>
//       </CheckboxGroup>
//       <Heading size="md">Volume:</Heading>
//       <RadioGroup value={volume} onChange={setVolume}>
//         <HStack>
//           <Radio value="">-disable-</Radio>
//           <Radio value="low">Low</Radio>
//           <Radio value="medium">Medium</Radio>
//           <Radio value="high">High</Radio>
//         </HStack>
//       </RadioGroup>
//       {/* 
//         location include
//         location exclude
//         device checkbox
//         os checkbox
//         connected only checkbox
//         trading volume radio
//         interests checkbox
//         chain checkbox
//        */}
//       {/* <Text>{volume}</Text> */}
//       <Heading size="md">Selected tags:</Heading>
//       <Text>{tagsList.join(",")}</Text>
//       <Heading size="md">Suitable publishers:</Heading>
//       <Text>{pubs.map(x => x.name).join(",")}</Text>
//     </VStack>
//   </Box>;
// }

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);
